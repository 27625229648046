import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import createConnector from "../core/createConnector.js";
import { getResults } from "../core/indexUtils.js";

function inferPayload(_ref) {
  var method = _ref.method,
      results = _ref.results,
      currentHit = _ref.currentHit;
  var index = results.index;
  var queryID = currentHit.__queryID;
  var objectIDs = [currentHit.objectID];

  if (!queryID) {
    throw new Error("Could not infer `queryID`. Ensure `clickAnalytics: true` was added with the Configure widget.\nSee: https://alg.li/VpPpLt");
  }

  switch (method) {
    case 'clickedObjectIDsAfterSearch':
      {
        var positions = [currentHit.__position];
        return {
          index: index,
          queryID: queryID,
          objectIDs: objectIDs,
          positions: positions
        };
      }

    case 'convertedObjectIDsAfterSearch':
      return {
        index: index,
        queryID: queryID,
        objectIDs: objectIDs
      };

    default:
      throw new Error("Unsupported method \"".concat(method, "\" passed to the insights function. The supported methods are: \"clickedObjectIDsAfterSearch\", \"convertedObjectIDsAfterSearch\"."));
  }
}

var wrapInsightsClient = function wrapInsightsClient(aa, results, currentHit) {
  return function (method, payload) {
    if (typeof aa !== 'function') {
      throw new TypeError("Expected insightsClient to be a Function");
    }

    var inferredPayload = inferPayload({
      method: method,
      results: results,
      currentHit: currentHit
    });
    aa(method, _objectSpread(_objectSpread({}, inferredPayload), payload));
  };
};

export default (function (insightsClient) {
  return createConnector({
    displayName: 'AlgoliaInsights',
    $$type: 'ais.insights',
    getProvidedProps: function getProvidedProps(props, _, searchResults) {
      var results = getResults(searchResults, {
        ais: props.contextValue,
        multiIndexContext: props.indexContextValue
      });
      var insights = wrapInsightsClient(insightsClient, results, props.hit);
      return {
        insights: insights
      };
    }
  });
});