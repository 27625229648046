import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import createConnector from "../core/createConnector.js";
import { refineValue, getCurrentRefinementValue, getResults } from "../core/indexUtils.js";

function getId() {
  return 'relevancyStrictness';
}

function getCurrentRefinement(props, searchState, context) {
  var id = getId();
  var currentRefinement = getCurrentRefinementValue(props, searchState, context, id);
  return currentRefinement;
}

export default createConnector({
  displayName: 'AlgoliaRelevantSort',
  $$type: 'ais.relevantSort',
  getProvidedProps: function getProvidedProps(props, _searchState, searchResults) {
    var results = getResults(searchResults, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    });

    if (!results) {
      return {
        isVirtualReplica: false,
        isRelevantSorted: false
      };
    }

    return {
      isVirtualReplica: results.appliedRelevancyStrictness !== undefined,
      isRelevantSorted: results.appliedRelevancyStrictness !== undefined && results.appliedRelevancyStrictness > 0
    };
  },
  getSearchParameters: function getSearchParameters(searchParameters, props, searchState) {
    return searchParameters.setQueryParameter('relevancyStrictness', getCurrentRefinement(props, searchState, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    }));
  },
  refine: function refine(props, searchState, nextRefinement) {
    var nextValue = _defineProperty({}, getId(), nextRefinement);

    var resetPage = true;
    return refineValue(searchState, nextValue, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    }, resetPage);
  }
});